import { useRouter } from 'next/router'
import { useEffect } from 'react'

const Index = () => {
  const router = useRouter()

  useEffect(() => {
    router.push({
      pathname: '/create',
      query: { ...router.query }
    })
  }, [router])

  return <></>
}

export default Index
